const ru = {
  title: 'Напишите нам',
  description: 'Вы можете оставить свою заявку или задать интересующий вас вопрос, заполнив форму. Мы свяжемся с вами в ближайшее время!',
  form: {
    name: {
      placeholder: 'Ваше имя',
    },
    email: {
      placeholder: 'Ваш Email',
    },
    phone: {
      placeholder: 'Ваш телефон',
    },
    text: {
      placeholder: 'Ваш вопрос',
    },
    button: {
      text: 'Отправить',
    },
  },
  contactsBlock: {
    title: 'Контактная информация',
    phone: 'Наш номер телефона',
    address: 'Наш адрес',
    workTime: 'Время работы',
    email: 'Наша почта',
  },
};

export default {
  ru,
};
