const ru = {
  title: 'Почему выбирают "София гранит"',
  labels: [
    'Мы изготавливаем памятники любой сложности',
    'Выполняем качественные художественные работы',
    'Хранение на производстве абсолютно бесплатно',
    'Осуществляем бесплатный выезд нашего мастера',
    'Перед работой разрабатываем макет',
    'Производим установку изделий',
  ],
};

export default {
  ru,
};
