export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1.0"},{"charset":"utf-8"},{"name":"description","content":""},{"name":"yandex-verification","content":"ceec1f72e4be0d56"},{"name":"og:type","content":"website"},{"name":"og:site_name","content":"Памятники из гранита и мрамора"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"/js/metrics.js","tagPosition":"bodyClose","async":true}],"noscript":[{"children":"Для правильной работы сайта включите JavaScript в браузере и обновите страницу.","tagPosition":"bodyOpen"},{"innerHTML":"<div><img src=\"https://mc.yandex.ru/watch/96313259\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div>","tagPosition":"bodyClose"}],"htmlAttrs":{"lang":"ru"},"charset":"utf-8","viewport":"width=device-width,initial-scale=1.0","title":"СОФИЯ ГРАНИТ - Памятники из гранита и мрамора"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"