const ru = {
  price: 'Цена',
  payButton: {
    text: 'Заказать'
  },
  fields: {
    memorial: 'Памятник',
    stand: 'Тумба',
    engraving: 'Гравировка',
    portrait: 'Портрет',
    flowerGarden: 'Цветник',
  },
};

export default {
  ru,
};
