import mitt from 'mitt';

const popupEventBus = mitt();

export default defineNuxtPlugin(() => {
  return {
    provide: {
      popupEventBus,
    },
  };
});
