const ru = {
  backCallPopup: {
    title: 'Есть вопросы?',
    description: 'Оставьте <i class="back-call__description-brown">номер</i> и&nbsp;мы&nbsp;вам перезвоним в&nbsp;<i class="back-call__description-brown">ближайшее</i> время',
    form: {
      name: {
        placeholder: 'Имя',
      },
      phone: {
        placeholder: 'Телефон',
      },
    },
    submitButton: 'Заказать',
    message: 'Здравствуйте. Перезвоните мне, пожалуйста, по указанному номеру.',
  },
  orderPopup: {
    title: 'Для заказа заполните форму и мы вам перезвоним',
    description: 'Оставьте <i class="back-call__description-brown">номер</i> и&nbsp;мы&nbsp;вам перезвоним в&nbsp;<i class="back-call__description-brown">ближайшее</i> время',
    form: {
      name: {
        placeholder: 'Имя',
      },
      phone: {
        placeholder: 'Телефон',
      },
    },
    submitButton: 'Заказать',
    message: 'Здравствуйте. Перезвоните мне, пожалуйста, по указанному номеру.',
  },
  successPopup: {
    title: 'Ваша заявка отправлена',
    description: 'Мы свяжемся с вами в ближайшее время!',
  },
};

export default {
  ru,
};
