import { defineStore } from 'pinia';
import type { IContacts } from '@/types';

interface IState {
  data: IContacts | null
}

export const useContactsStore = defineStore('contacts', {
  state: (): IState => {
    return {
      data: null,
    };
  },
  getters: {},
  actions: {
    async getAll() {
      const { findOne } = useStrapi();
      const response = await findOne<IContacts>('contact', {
        populate: 'deep',
      });

      this.data = response.data as unknown as IContacts;
    }
  },
});
