import { defineStore } from 'pinia';
import type { PaginationByPage } from '@nuxtjs/strapi/dist/runtime/types/v4';
import type {
  IFile,
  IPortfolioItem,
} from '@/types';

interface IState {
  data: IPortfolioItem[]
}

export const usePortfoliosStore = defineStore('portfolios', {
  state: (): IState => {
    return {
      data: [],
    };
  },
  getters: {
    allImages (state): IFile[] {
      return state.data
        .map((portfolio) => {
          const files = [portfolio.cover].concat(portfolio.files || []);

          return files;
        })
        .flat();
    }
  },
  actions: {
    async getAll(pagination: Partial<PaginationByPage> = {}) {
      const { find } = useStrapi();
      const response = await find<IPortfolioItem>('portfolios', {
        populate: 'deep',
        sort: [
          'createdAt:asc',
        ],
        pagination: {
          ...({
            page: 1,
            pageSize: 300,
            withCount: true,
          }),
          ...pagination,
        },
      });

      this.data = response.data as unknown as IPortfolioItem[];
    }
  },
});
