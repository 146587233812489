import {
  defineRule,
  configure,
} from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  regex,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import ru from '@vee-validate/i18n/dist/locale/ru.json';

export default defineNuxtPlugin((nuxtApp) => {
  configure({
    generateMessage: localize('en', ru),
  });

  defineRule('required', required);
  defineRule('email', email);
  defineRule('min', min);
  defineRule('max', max);
  defineRule('regex', regex);
});
