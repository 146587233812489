import {
  theHeaderTranslations,
  theFooterTranslations,
  theHeroTranslations,
  theCatalogTranslations,
  theAboutTranslations,
  thePortfolioTranslations,
  theContactsTranslations,
  popupsTranslations,
  contactsFormTranslations,
  memorialCardTranslations,
} from '@/components';

const app = {
  callButton: 'Обратный звонок',
  more: 'Подробнее',
};

const pages = {
  catalog: {
    title: 'Каталог памятников',
  },
  portfolio: {
    title: 'Наши работы',
  },
  promotions: {
    title: 'Скидки и акции',
  },
  decors: {
    title: 'Оформление',
  },
};

export default {
  app,
  pages,
  theHeader: theHeaderTranslations.ru,
  theFooter: theFooterTranslations.ru,
  theHero: theHeroTranslations.ru,
  theCatalog: theCatalogTranslations.ru,
  theAbout: theAboutTranslations.ru,
  thePortfolio: thePortfolioTranslations.ru,
  theContacts: theContactsTranslations.ru,
  popups: popupsTranslations.ru,
  contactsForm: contactsFormTranslations.ru,
  memorialCard: memorialCardTranslations.ru,
};
