// https://nuxt.com/docs/getting-started/configuration#app-configuration
export default defineAppConfig({
  routes: [
    {
      path: '/',
      name: 'Main',
      navigationTitle: 'Главная',
    },
    {
      path: '/catalog',
      name: 'Catalog',
      navigationTitle: 'Каталог',
    },
    {
      path: '/decors',
      name: 'Decors',
      navigationTitle: 'Оформление',
    },
    {
      path: '/about',
      name: 'About',
      navigationTitle: 'О компании',
    },
    {
      path: '/portfolio',
      name: 'Portfolio',
      navigationTitle: 'Наши работы',
    },
    {
      path: '/contacts',
      name: 'Contacts',
      navigationTitle: 'Контакты',
    },
    {
      path: '/promotions',
      name: 'Promotions',
      navigationTitle: 'Скидки и акции',
    },
  ],
});

interface IRoute {
  path: string
  name: string
  navigationTitle: string
}

declare module 'nuxt/schema' {
  interface AppConfigInput {
    routes: IRoute[]
  }

  interface AppConfig {
    routes: IRoute[]
  }
}
