const ru = {
  name: {
    placeholder: 'Ваше имя',
  },
  email: {
    placeholder: 'Ваш Email',
  },
  phone: {
    placeholder: 'Ваш телефон',
  },
  text: {
    placeholder: 'Ваш вопрос',
  },
  submitButton: {
    text: 'Отправить',
  },
};

export default {
  ru,
};
