const ru = {
  more: 'Все акции',
  labels: {
    time: 'Быстрое обслуживание',
    open: 'Работаем более 10 лет',
    shield: 'Гарантия качества 100%',
  },
};

export default {
  ru,
};
