import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import strapi_plugin_35gTOGMBRa from "/app/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import composition_sLxaNGmlSL from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import eventBus_P6CbRuQa3W from "/app/plugins/eventBus.ts";
import veevalidate_components_HZdjzYOWQC from "/app/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/app/plugins/veevalidate-rules.ts";
import vue_easy_lightbox_client_lWqeizDQUL from "/app/plugins/vue-easy-lightbox.client.ts";
import vue_select_8ji40gexSh from "/app/plugins/vue-select.ts";
import vue3_pagination_mL23PBf9HI from "/app/plugins/vue3-pagination.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  strapi_plugin_35gTOGMBRa,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  eventBus_P6CbRuQa3W,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq,
  vue_easy_lightbox_client_lWqeizDQUL,
  vue_select_8ji40gexSh,
  vue3_pagination_mL23PBf9HI
]