import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as catalogmbyzFSoe6qMeta } from "/app/pages/catalog.vue?macro=true";
import { default as _91slug_93tM9kmbpQ9oMeta } from "/app/pages/catalog/[slug].vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as decors3S8EORXJ9tMeta } from "/app/pages/decors.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as portfolioNCwKyeSyVbMeta } from "/app/pages/portfolio.vue?macro=true";
import { default as promotionsV1QWs3ugnkMeta } from "/app/pages/promotions.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___ru",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    children: [],
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect || undefined,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    path: catalogmbyzFSoe6qMeta?.path ?? "/catalog",
    children: [
  {
    name: _91slug_93tM9kmbpQ9oMeta?.name ?? "catalog-slug___ru",
    path: _91slug_93tM9kmbpQ9oMeta?.path ?? ":slug",
    children: [],
    meta: _91slug_93tM9kmbpQ9oMeta || {},
    alias: _91slug_93tM9kmbpQ9oMeta?.alias || [],
    redirect: _91slug_93tM9kmbpQ9oMeta?.redirect || undefined,
    component: () => import("/app/pages/catalog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvJKqjSbZLzMeta?.name ?? "catalog___ru",
    path: indexvJKqjSbZLzMeta?.path ?? "",
    children: [],
    meta: indexvJKqjSbZLzMeta || {},
    alias: indexvJKqjSbZLzMeta?.alias || [],
    redirect: indexvJKqjSbZLzMeta?.redirect || undefined,
    component: () => import("/app/pages/catalog/index.vue").then(m => m.default || m)
  }
],
    name: catalogmbyzFSoe6qMeta?.name ?? undefined,
    meta: catalogmbyzFSoe6qMeta || {},
    alias: catalogmbyzFSoe6qMeta?.alias || [],
    redirect: catalogmbyzFSoe6qMeta?.redirect || undefined,
    component: () => import("/app/pages/catalog.vue").then(m => m.default || m)
  },
  {
    name: contactsHSHl3sOssUMeta?.name ?? "contacts___ru",
    path: contactsHSHl3sOssUMeta?.path ?? "/contacts",
    children: [],
    meta: contactsHSHl3sOssUMeta || {},
    alias: contactsHSHl3sOssUMeta?.alias || [],
    redirect: contactsHSHl3sOssUMeta?.redirect || undefined,
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: decors3S8EORXJ9tMeta?.name ?? "decors___ru",
    path: decors3S8EORXJ9tMeta?.path ?? "/decors",
    children: [],
    meta: decors3S8EORXJ9tMeta || {},
    alias: decors3S8EORXJ9tMeta?.alias || [],
    redirect: decors3S8EORXJ9tMeta?.redirect || undefined,
    component: () => import("/app/pages/decors.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ru",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    children: [],
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioNCwKyeSyVbMeta?.name ?? "portfolio___ru",
    path: portfolioNCwKyeSyVbMeta?.path ?? "/portfolio",
    children: [],
    meta: portfolioNCwKyeSyVbMeta || {},
    alias: portfolioNCwKyeSyVbMeta?.alias || [],
    redirect: portfolioNCwKyeSyVbMeta?.redirect || undefined,
    component: () => import("/app/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: promotionsV1QWs3ugnkMeta?.name ?? "promotions___ru",
    path: promotionsV1QWs3ugnkMeta?.path ?? "/promotions",
    children: [],
    meta: promotionsV1QWs3ugnkMeta || {},
    alias: promotionsV1QWs3ugnkMeta?.alias || [],
    redirect: promotionsV1QWs3ugnkMeta?.redirect || undefined,
    component: () => import("/app/pages/promotions.vue").then(m => m.default || m)
  }
]